<style>
@import "./../../../assets/css/dashboard_sector.css";
</style>

<script>
/**
 * Earning Reports component
 */

import axios from "axios";
import Config from "../../../config.js";

export default {
  props: {
    client_id: [Object, String],
    year: String,
    segment_id: [Object, String],
  },
  data() {
    return {
      showMany: false,
      data_url: Config,
      userStart: JSON.parse(localStorage.getItem("user")),
      dataSector: "",
      cssProps: {
        backgroundImage: `url(${require("@/assets/images/orangok.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "fix",
        backgroundPosition: "right bottom",
      },
      currentWidth: window.innerWidth,
      totalItems: 3,
    };
  },
  mounted() {
    this.ApiCallSector();
    this.resizeWindow();
  },
  methods: {
    resizeWindow() {
      this.setItemByCondition();
      window.addEventListener("resize", () => {
        this.setItemByCondition();
      });
    },
    setItemByCondition() {
      this.currentWidth = window.innerWidth;
      if (window.innerWidth > 1900) {
        this.totalItems = 4;
      } else {
        this.totalItems = 3;
      }
    },
    showSector() {
      if (this.showMany) {
        this.showMany = false;
      } else {
        this.showMany = true;
      }
    },

    async setColor(status) {
      if (Config.modeDev) console.log(status);
      return;
    },

    async ApiCallSector() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.year != "") {
        formData.append("year", this.year);
      }
      if (this.segment_id != "") {
        formData.append("segment_id", this.segment_id.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashbarodgetSektor,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data", "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            let data = res.data.resultdata;

            let nilai1 = [];
            let nilai2 = [];
            let data1 = [];

            for (let i = 0; i < data.length; i++) {
              if (data[i].nilai_persen_kesesuaian > 0) {
                nilai1[i] = parseInt(data[i].nilai_persen_kesesuaian.toFixed());
                nilai2[i] =
                  100 - parseInt(data[i].nilai_persen_kesesuaian.toFixed());

                data1[i] = {
                  total_assessment: data[i].total_assessment,
                  name: data[i].nama_sektor,
                  image: data[i].image,
                  series: [nilai1[i], nilai2[i]],
                  chartOptions: {
                    plotOptions: {
                      pie: {
                        offsetY: 30,
                        customScale: 1.3,
                        donut: {
                          size: "75%",
                        },
                      },
                    },
                    colors: ["#6342B8", "#A0EFE9"],
                    dataLabels: {
                      enabled: true,
                      formatter: function (val) {
                        return val + "%";
                      },
                      textAnchor: "middle",
                      distributed: false,
                      style: {
                        colors: [
                          function () {
                            // console.log(w);
                            return "#737373";
                          },
                        ],
                      },
                      background: {
                        enabled: false,
                        foreColor: "#000000",
                        padding: 5,
                        borderRadius: 0,
                        borderWidth: 0,
                        borderColor: "#fff",
                        opacity: 1,
                      },
                    },
                    chart: {
                      type: "donut",
                      width: "100%",
                      dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 12,
                        color: "#CF9FFF",
                        opacity: 0.4,
                      },
                    },
                    legend: { show: false },
                    labels: ["Sesuai", "Tidak Sesuai"],
                    yaxis: {
                      show: true,
                      showAlways: true,
                      labels: {
                        show: true,
                        color: "red",
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 280,
                        options: {
                          chart: {
                            width: 100,
                          },
                          legend: {
                            position: "bottom",
                          },
                        },
                      },
                    ],
                  },
                };
              } else {
                data1[i] = {
                  total_assessment: data[i].total_assessment,
                  name: data[i].nama_sektor,
                  image: data[i].image,
                  series: [100],
                  chartOptions: {
                    plotOptions: {
                      pie: {
                        offsetY: 30,
                        customScale: 1.3,
                        donut: {
                          // size: "130%",
                          size: "75%",
                        },
                      },
                    },
                    chart: {
                      height: "800px",
                      type: "donut",
                      dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 12,
                        color: ["#E0B0FF"],
                        opacity: 0.4,
                      },
                    },
                    legend: { show: false },
                    labels: ["Tidak Ada Data"],
                    colors: ["#D9D9D9"],
                    dataLabels: {
                      enabled: false,
                      color: "red",
                    },
                    tooltip: {
                      enabled: false,
                    },
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200,
                          },
                          legend: {
                            position: "bottom",
                          },
                        },
                      },
                    ],
                  },
                };
              }
            }
            this.dataSector = data1;
            if (Config.modeDev) console.log(this.dataSector);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },
  },
};
</script>
<style>
.fade-enter-active{
  transition: opacity 1.5s ease;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from{
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}
</style>
<template>
  <div class="card vector-section">
    <!--    <div class="bg-vector-bluer"></div> -->
    <div class="card-body position-relative">
      <div class="row flex-nowrap">
        <div
          class="title-content pr-2 d-flex justify-content-center align-items-center"
        >
          <img
            style="position: absolute; left: -36px; top: -30px; margin: auto"
            src="@/assets/images/ngopi.png"
          />
          <h4 class="card-title mb-4 font-poppins titleBox">Sector Criteria</h4>
          <!-- <span class="font-poppins" style="font-size: 15px"
              >Performance di setiap sektor</span
            > -->
        </div>

        <div class="lists">
          <div
            class="row sector-card-list font-poppins gap-5"
            v-bind:class="`${showMany ? 'ShowAll' : ''}`"
          >
            <template v-for="(v, i) in dataSector">
              <transition name="fade" v-bind:key="i">
                <div
                  class="col"
                  v-if="i <= totalItems || showMany"
                >
                  <div class="sector-card">
                    <div
                      class="d-flex justify-content-center flex-column font-poppins"
                    >
                      <div class="text-center logo">
                        <img width="40" v-bind:src="v.image" />
                      </div>
                      <div class="title text-center font-weight-bold">
                        <h4
                          class="font-poppins font-weight-bold"
                          style="font-size: 15px"
                        >
                          {{ v.name }}
                        </h4>
                      </div>
                      <div class="pie-cart">
                        <apexchart
                          type="donut"
                          :options="v.chartOptions"
                          :series="v.series"
                        ></apexchart>
                        <div class="info text-center">
                          <span class="font-poppins mb-0">
                            {{ v.total_assessment }}
                          </span>
                          <p class="mt-0">Project</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </template>
            <transition name="bounce">
              <div class="col">
                <img
                  @click="showSector"
                  v-if="showMany"
                  style="cursor: pointer"
                  src="@/assets/images/close_sector.png"
                />
              </div>
            </transition>
          </div>
        </div>
        <div
          @click="showSector"
          class="text-center"
          style="
            border-radius: 32px;
            cursor: pointer;
            position: absolute;
            right: -70px;
          "
        >
          <transition name="slide-fade">
            <img
              v-if="!showMany"
              width="120"
              src="@/assets/images/show_sector.png"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
