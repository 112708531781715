<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";

// import Stat from "./widget";
// import BarChartTotalProject from "./BarChartTotalProject";
// import BarChartTotalContract from "./BarChartTotalContract";
import axios from "axios";
import Config from "../../../config.js";
import { Helper } from "../../../helper.js";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
// import RevenueAnalytics from "./revenue";
// import SalesAnalytics from "./sales-analytics";
import Sector from "./sector";
// import Sources from "./sources";
// import RecentActivity from "./recent-activity";
// import RevenueLocation from "./revenue-location";
import Markers from "./markers";
// import PieChart from "./PieChart";
// import Chat from "./chat";
// import Transaction from './transaction';
import { Circle8 } from "vue-loading-spinner";

/**
 * Dashboard component
 */
export default {
  mixins: [Helper],
  components: {
    Layout,
    DatePicker,
    // PageHeader,
    Circle8,
    Multiselect,
    // Stat,
    // PieChart,
    // RevenueAnalytics,
    // SalesAnalytics,
    Sector,
    // Sources,
    // RecentActivity,
    // RevenueLocation,
    // BarChartTotalProject,
    // BarChartTotalContract,
    Markers,
    // Chat,
    // Transaction
  },
  data() {
    return {
      loading_client: false,
      dataSelectClient: [],

      dataColorTop5: [
        [
          "rgba(31, 111, 105, 0.9)",
          "rgba(46, 162, 154, 0.9)",
          "rgba(64, 200, 190, 0.9)",
          "rgba(160, 239, 233, 0.9)",
          "rgba(210, 255, 252, 0.9)",
        ],
        ["#D2FFFC", "#D2FFFC", "#000000", "#000000", "#000000"],
      ],

      dataColorHI5: [
        [
          "rgba(66, 41, 131, 0.9)",
          "rgba(99, 66, 184, 0.9)",
          "rgba(155, 119, 248, 0.9)",
          "rgba(194, 170, 255, 0.9)",
          "rgba(223, 210, 255, 0.9)",
        ],
        ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000", "#000000"],
      ],

      dataMasterSO: [],

      dataTopFive: "",
      dataHighIssue: "",
      total_project: "0",

      total_company: 0,

      dataProjectYear_view: false,
      dataProjectYear: {
        label: [],
        dataset: [],
      },

      client_id: "",
      v_data_segment_ow: "",
      total_kontrak: "",

      dataSumContract_view: false,
      dataSumContract: {
        total_kontrak: 0,
        label: [],
        dataset: [],
      },

      dataSection_view: false,
      dataSection: [],

      dataSelectClient_input: "",
      dataMasterSO_input: "",
      searchYear: "",
      searchYearProps: "",
      refreshDashboardNow: false,

      data_url: Config,
      userStart: JSON.parse(localStorage.getItem("user")),
      // title: "Dashboard",
      // items: [
      //   {
      //     text: "Karya Assessment",
      //   },
      //   {
      //     text: "Dashboard",
      //     active: true
      //   }
      // ],

      dataSecmentOwner: "",
      dataSecmentOwner_temp: "",
      dataSecmentOwner_view: false,
      labelPlacementChart: {
        data: {
          labels: ["A", "B", "C", "D", "E", "F", "G"],
          series: [
            [5, 4, 3, 7, 5, 10, 3],
            [3, 2, 9, 5, 4, 6, 4],
          ],
        },
        options: {
          height: 300,
          axisX: {
            // On the x-axis start means top and end means bottom
            position: "end",
          },
          axisY: {
            // On the y-axis start means left and end means right
            position: "start",
          },
        },
      },
      stackBarChartSector: {},
      stackBarChart: {
        data: {
          labels: ["2019", "2020", "2021", "2022"],
          series: [
            [60, 80, 40, 40],
            [40, 20, 60, 60],
          ],
        },
        options: {
          stackBars: true,
          axisY: {
            labelInterpolationFnc: (value) => {
              return value + "";
            },
          },
          height: 300,
        },
      },
    };
  },
  computed: {
    searchYear_computed: {
      get() {
        return this.searchYear;
      },
      set(val) {
        if (val == null) {
          this.searchYear = "";
          this.searchYearProps = "";
          this.refreshDashboard();
        } else {
          this.searchYear = val;
          this.searchYearProps = moment(val).format("YYYY");
          this.refreshDashboard();
        }
      },
    },

    dataMasterSO_computed: {
      get() {
        return this.dataMasterSO_input;
      },
      set(val) {
        if (val == null) {
          this.dataMasterSO_input = "";
        } else {
          this.dataMasterSO_input = val;
          this.refreshDashboard();
        }
      },
    },

    dataSelectClient_computed: {
      get() {
        return this.client_id;
      },
      set(val) {
        if (val == null) {
          this.client_id = "";
        } else {
          this.client_id = val;

          this.refreshDashboard();
        }
      },
    },
  },
  mounted() {
    this.apiCallStart();
  },

  methods: {
    apiCallStart() {
      this.ApiCallSumContract();
      this.ApiCallSector();
      this.ApiCallMasterSO();
      this.ApiCallTotalCompany();
      this.ApiCallTotalProject();
      this.ApiCallProjectPerYear();
      this.ApiCallDataClient();
      this.ApiCallTopFive();
      this.ApiCallHighIssue();
      this.ApiCallTotalKontrak();
    },

    async refreshDashboard() {
      this.refreshDashboardNow = true;

      this.total_company = 0;
      this.dataSumContract = {
        total_kontrak: 0,
        label: [],
        dataset: [],
      };
      this.dataProjectYear = {
        label: [],
        dataset: [],
      };
      this.total_project = 0;
      this.dataProjectYear = 0;
      this.dataTopFive = [];
      this.dataHighIssue = [];
      this.dataSection = [];

      await this.apiCallStart();
      this.refreshDashboardNow = false;
    },

    cleardataMasterSO() {
      this.dataMasterSO_input = "";
      this.refreshDashboard();
    },

    cleardataSelectClient() {
      this.client_id = "";
      this.refreshDashboard();
    },

    async ApiCallDataClient() {
      this.loading_client = true;
      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);

      if(this.dataMasterSO_input != ""){
        formData.append("segment_id", this.dataMasterSO_input.id);
      }
       

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getContact,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectClient = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });

      this.loading_client = false;
    },

    dataSectorSetChart() {
      let series1 = [];
      let series2 = [];

      for (let ii = 0; ii < this.v_data_segment_ow.series.length; ii++) {
        if (this.v_data_segment_ow.series[ii].toFixed() > 0) {
          series1[ii] = this.v_data_segment_ow.series[ii].toFixed();
          series2[ii] = 100 - this.v_data_segment_ow.series[ii].toFixed();
        } else {
          series1[ii] = 0;
          series2[ii] = 0;
        }
      }

      let dataLabel = [];
      for (
        let ii = 0;
        ii < this.dataSecmentOwner_temp.data_segment_ques.length;
        ii++
      ) {
        dataLabel[ii] = this.dataSecmentOwner_temp.data_segment_ques[ii].name;
      }

      this.dataSecmentOwner = {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "45%",
              endingShape: "rounded",
              borderRadius: 5,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          colors: ["#3FB782", "#172957"],
          xaxis: {
            categories: dataLabel,
            position: "bottom",
            labels: {
              show: true,
              rotate: 0,
              trim: true,
              hideOverlappingLabels: false,
            },
          },
          yaxis: {
            title: {
              text: " ",
            },
          },
          grid: {
            borderColor: "#f1f1f1",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return " " + val + "%";
              },
            },
          },
        },
        series: [
          {
            name: "Sesuai",
            data: series1,
          },
          {
            name: "Tidak Sesuai",
            data: series2,
          },
        ],
      };
    },

    async ApiCallTotalCompany() {
      let formData = null;
      formData = new URLSearchParams();

      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetTotalCompany,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);

          if (res.data.codestatus == "S") {
            this.total_company = res.data.resultdata[0].count;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallMasterSO() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getSegmentasiOwner,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (res.data.codestatus == "S") {
            this.dataMasterSO = res.data.resultdata;
            if (Config.modeDev) console.log(this.dataMasterSO);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (Config.modeDev) console.log(error);
          }
        });
    },

    async ApiCallTopFive() {
      let formData = new URLSearchParams();

      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetTop5,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (res.data.codestatus == "S") {
            let dataQw = res.data.resultdata;

            let n;
            for (let i = 0; i < dataQw.length; i++) {
              n = dataQw[i].total_nilai / 1000000000000;
              dataQw[i].total_nilai = n.toFixed(3).slice(0, -1);
            }

            this.dataTopFive = dataQw;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallHighIssue() {
      let formData = new URLSearchParams();
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetHighlightIssue,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (res.data.codestatus == "S") {
            this.dataHighIssue = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallProjectPerYear() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      this.dataProjectYear_view = false;
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetProjectPerYear,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (res.data.codestatus == "S") {
            let dataProjectYear = res.data.resultdata[0];

            let maxi = 0;
            for (let i = 0; i < dataProjectYear.label_val.length; i++) {
              if (maxi < dataProjectYear.label_val[i]) {
                maxi = dataProjectYear.label_val[i];
              }
            }

            this.dataProjectYear = {
              chartOptions: {
                chart: {
                  type: "donut",
                  width: "100%",
                },
                plotOptions: {
                  pie: {
                    customScale: 0.7,
                    startAngle: -90,
                    endAngle: 90,
                    offsetY: 32,
                    donut: {
                      background: "#303249",
                      size: "140%",
                      labels: {
                        show: false,
                        name: {
                          show: true,
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: false,
                },
                states: {
                  normal: {
                    filter: {
                      type: "none",
                      value: 0,
                    },
                  },
                  hover: {
                    filter: {
                      type: "lighten",
                      value: 0.005,
                    },
                  },
                  active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                      type: "darken",
                      value: 0.35,
                    },
                  },
                },
                tooltip: {
                  enabled: false,
                },
                dataLabels: {
                  enabled: true,
                  style: {
                    fontSize: "20px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "600",
                    lineHeight: "30px",
                  },

                  formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    const name_val = opts.w.globals.series[opts.seriesIndex];
                    // return [name, val.toFixed(1) + "%"];
                    return [name, " " + name_val + " Projects"];
                  },
                },
                labels: dataProjectYear.labels,
                colors: [
                  "rgba(31, 111, 105, 1)",
                  "rgba(59, 163, 156, 1)",
                  "rgba(75, 197, 188, 1)",
                  "rgba(161, 232, 227, 1)",
                  "rgba(206, 246, 244, 1)",
                ],
                yaxis: {
                  show: true,
                  showAlways: true,
                  labels: {
                    show: true,
                  },
                },
                responsive: [
                  {
                    breakpoint: 280,
                    options: {
                      chart: {
                        width: 100,
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              },
              series: dataProjectYear.label_val,
            };
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.dataProjectYear_view = true;
    },

    async ApiCallTotalKontrak() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getTotalNilaiKontrak,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);

          if (res.data.codestatus == "S") {
            let total_kontrak = res.data.resultdata[0].total / 1000000000000;
            this.total_kontrak = total_kontrak.toFixed(2).slice(0, -1);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallTotalProject() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetTotalProject,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);

          if (res.data.codestatus == "S") {
            this.total_project = res.data.resultdata[0].total;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallSegmentOwner() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }

      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }

      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetSegment,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);

          if (res.data.codestatus == "S") {
            this.dataSecmentOwner_temp = res.data.resultdata[0];
            if (Config.modeDev) console.log(this.dataSecmentOwner_temp);
            this.v_data_segment_ow =
              this.dataSecmentOwner_temp.data_segmentasi[0];

            this.dataSectorSetChart();

            this.dataSecmentOwner_view = true;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallSector() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      this.dataSection_view = false;
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashgetSectionPerYear,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);

          if (res.data.codestatus == "S") {
            let data = res.data.resultdata;
            let dataSection = [];

            let label = [];
            let series1 = [];
            let series2 = [];

            if (data.length > 0) {
              let datass = [];
              for (let i = 0; i < data[0].datas.length; i++) {
                datass[i] = {
                  year: data[i].datas[i].year,
                  total: 0,
                  tidal_sesuai: 100,
                };
              }

              data[data.length] = {
                name: "OTHER",
                image:"https://i.ibb.co/8d27MpY/helmet-1.png",
                datas: datass,
              };

              console.log(data);
            }

            for (let i = 0; i < data.length; i++) {
              label = [];
              series1 = [];
              series2 = [];
              for (let ii = 0; ii < data[i].datas.length; ii++) {
                label[ii] = data[i].datas[ii].year;
                if (data[i].datas[ii].total > 0) {
                  series1[ii] = data[i].datas[ii].total;
                  series2[ii] = 100 - data[i].datas[ii].total;
                } else {
                  series1[ii] = 0;
                  series2[ii] = 0;
                }
              }

              dataSection[i] = {
                image: data[i].image,
                section_name: data[i].name,
                chartOptions: {
                  chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    toolbar: {
                      show: false,
                    },
                    // zoom: {
                    //   enabled: true,
                    // },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      // borderRadius: 10,
                      dataLabels: {
                        total: {
                          enabled: false,
                          style: {
                            fontSize: "13px",
                            fontWeight: 900,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  colors: ["#6E59A4", "#DBDBDB"],
                  yaxis:{
                    max: 100,
                  },
                  xaxis: {
                    categories: label,
                    labels:{
                      show:true,
                     style: {
                         fontWeight: 400,
                     }
                  },
                  },
                  grid: {
                    borderColor: "#f1f1f1",
                  },
                  fill: {
                    opacity: 1 ,
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return " " + val + " %";
                      },
                    },
                  },
                },
                series: [
                  {
                    name: "Sesuai",
                    data: series1,
                  },
                  {
                    name: "Tidak Sesuai",
                    data: series2,
                  },
                ],
              };
            }

            this.dataSection = dataSection;
            if (Config.modeDev) console.log(this.dataSection);

            this.dataSection_view = true;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },

    async ApiCallSumContract() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }
      if (this.dataMasterSO_input != "") {
        formData.append("segment_id", this.dataMasterSO_input.id);
      }

      this.dataSumContract_view = false;
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getJumlahKontrak,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data" "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            let data = res.data.resultdata;

            let nilai_kontrak = data[0].total_kontrak / 1000000000000;
            let aa = "";
            let maxi = 0;
            for (let i = 0; i < data[0].label_val.length; i++) {
              aa = data[0].label_val[i] / 1000000000000;
              if (maxi < aa.toFixed(3).slice(0, -1)) {
                maxi = aa.toFixed(3).slice(0, -1);
              }
              data[0].label_val[i] = parseFloat(aa.toFixed(3).slice(0, -1));
            }
            maxi = parseFloat(maxi) + parseFloat(maxi) / 2;

            this.dataSumContract = {
              total_kontrak: nilai_kontrak.toFixed(3).slice(0, -1),
              label: data[0].labels,
              chartOptions: {
                chart: {
                  type: "donut",
                  width: "100%",
                },
                plotOptions: {
                  pie: {
                    customScale: 0.7,
                    startAngle: -90,
                    endAngle: 90,
                    offsetY: 32,
                    donut: {
                      background: "#303249",
                      size: "140%",
                      labels: {
                        show: false,
                        name: {
                          show: true,
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: false,
                },
                states: {
                  normal: {
                    filter: {
                      type: "none",
                      value: 0,
                    },
                  },
                  hover: {
                    filter: {
                      type: "lighten",
                      value: 0.005,
                    },
                  },
                  active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                      type: "darken",
                      value: 0.35,
                    },
                  },
                },
                tooltip: {
                  enabled: false,
                },
                dataLabels: {
                  enabled: true,
                  style: {
                    fontSize: "18px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                  },

                  formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    const name_val = opts.w.globals.series[opts.seriesIndex];
                    // return [name, val.toFixed(1) + "%"];
                    return [name, "Rp " + name_val + " T"];
                  },
                },
                labels: data[0].labels,
                colors: [
                  "#4D3787",
                  "rgba(106, 77, 183, 1)",
                  "rgba(156, 124, 240, 1)",
                  "rgba(191, 170, 246, 1)",
                  "rgba(217, 206, 246, 1)",
                ],
                yaxis: {
                  show: true,
                  showAlways: true,
                  labels: {
                    show: true,
                  },
                },
                responsive: [
                  {
                    breakpoint: 280,
                    options: {
                      chart: {
                        width: 100,
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              },
              series: data[0].label_val,
            };

            if (Config.modeDev) console.log(this.dataSumContract);
            this.dataSumContract_view = true;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            console.log(error);
          }
        });
    },
  },
};
</script>

<style>

.overflowShow:hover{
  overflow: visible !important;
  height:min-content !important;
  min-height: 50px;
}

.assesment-criteria-card-list .apexcharts-xaxis text{
  fill: #000000;
  font-weight:bold !important;
}

.assesment-criteria-card-list .apexcharts-yaxis text{
  fill: #000000;
}

.assesment-criteria-card-list .apexcharts-legend-text{
  font-size: 14px !important;
  font-weight: 600 !important;
}

.mx-datepicker input{
  height: 38.04px;
}
</style>

<template>
  <!-- Filter 
  
  linear-gradient(          180.1deg,          #008ba95e 100%,          rgba(255, 255, 255, 0) 90.91%        ),url(/img/bg.3baf7ac9.png)
  linear-gradient(          180.1deg,          #008ba95e 100%,          rgba(255, 255, 255, 0) 90.91%        ),url(/img/bg.3baf7ac9.png)
   -->

  <Layout
    class="bg"
    v-bind:style="{
      backgroundImage:
        'linear-gradient(to bottom, rgba(0, 139, 169, 0.32), rgba(255, 255, 255, 0)), url(' +
        require('@/assets/images/bg.png') +
        ')',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    }"
  >
    <div
      style="
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180.1deg,
          rgba(0, 139, 169, 0.32) 0.09%,
          rgba(255, 255, 255, 0) 99.91%
        );
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3));
      "
    >
      <div class="container-fluid">
        <!-- Filter  -->
        <div class="row justify-content-end pt-5 px-0 filter-section">
          <div
            class="col d-inline-flex align-items-center justify-content-end p dpvp"
          >
            <span class="title">Year</span>
            <date-picker
              v-model="searchYear_computed"
              type="year"
              lang="en"
              placeholder="Select Year"
              style="width: 225px;"
            ></date-picker>
          </div>
          <div
            class="col d-inline-flex align-items-center justify-content-end position-relative"
          >
            <span class="title">Segment </span>

            <multiselect
              style="width: 333px"
              :clearOnSelect="true"
              :closeOnSelect="true"
              :options="dataMasterSO"
              v-model="dataMasterSO_computed"
              :show-labels="false"
              label="name"
            ></multiselect>
            <icon
              @click="cleardataMasterSO()"
              v-if="dataMasterSO_input != ''"
              class="ri-close-circle-fill position-absolute"
              style="font-size: 20px; right: 20px; cursor: pointerl"
            ></icon>
          </div>
          <div
            class="col d-inline-flex align-items-center justify-content-end position-relative px-0"
          >
            <span class="title">Client </span>
            <multiselect
              style="width: 100%"
              :clearOnSelect="true"
              :closeOnSelect="true"
              :options="dataSelectClient"
              v-model="dataSelectClient_computed"
              :show-labels="false"
              label="name"
            ></multiselect>
            <icon
              @click="cleardataSelectClient()"
              v-if="client_id != ''"
              class="ri-close-circle-fill position-absolute"
              style="font-size: 20px; right: 20px; cursor: pointerl"
            ></icon>
          </div>
        </div>

        <!-- Summary Total-->
        <div class="row total-section justify-content-between gap-60 pt-5">
          <!-- <PageHeader :title="title" :items="items" /> -->
          <div class="">
            <div
              class="card card-top position-relative mb-0"
              style="background-color: #303249"
            >
              <div
                class="card-body px-4 overflow-hidden"
                style="font-family: poppins"
              >
                <p class="title">Total Companies</p>
                <p class="total">
                  {{ total_company }}
                </p>
              </div>
              <img
                style="
                  position: absolute;
                  bottom: 1px;
                  height: 202px;
                  left: 12px;
                "
                src="@/assets/images/b1.png"
              />
            </div>
          </div>
          <!-- <div class="col-12 bayanganbawah"></div> -->

          <div class="">
            <div
              class="card position-relative mb-0 card-top"
              style="background-color: rgba(156, 174, 133, 1)"
            >
              <div class="card-body" style="font-family: poppins">
                <p class="title">Total Contract</p>
                <p class="total">{{ total_kontrak }} T</p>
              </div>
              <img
                style="
                  position: absolute;
                  bottom: 2px;
                  height: 193px;
                  left: 0px;
                "
                src="@/assets/images/b3.png"
              />
            </div>
            <!-- <div class="col-12 bayanganbawah"></div> -->
          </div>
          <div class="">
            <div
              class="card position-relative mb-0 card-top"
              style="background-color: #56a5a3"
            >
              <div class="card-body" style="font-family: poppins">
                <p class="title">Total Project</p>
                <p class="total">
                  {{ total_project }}
                </p>
              </div>
              <img
                style="position: absolute; bottom: 1px; height: 160px; left: 0x"
                src="@/assets/images/b2.png"
              />
            </div>
            <!-- <div class="col-12 bayanganbawah"></div> -->
          </div>
        </div>

        <!-- Project Location -->
        <div class="row p-0">
          <div class="col-lg-12">
            <h4
              class="card-title font-poppins titleBox text-white"
              style="
                font-size: 30px;
                font-weight: 600;
                letter-spacing: 0px;
                padding: 30px 0;
              "
            >
              Project Location
            </h4>
            <Markers
              v-if="!refreshDashboardNow"
              :client_id="client_id"
              :year="searchYearProps"
              :segment_id="dataMasterSO_input"
            />
          </div>
        </div>

        <!-- Sector Criteria -->

        <div
          class="row justify-content-around p-0 pt-5"
          style="padding-bottom: 28px"
        >
          <div class="col-lg-12 p-0">
            <sector
              v-if="!refreshDashboardNow"
              :client_id="client_id"
              :year="searchYearProps"
              :segment_id="dataMasterSO_input"
            />
          </div>
        </div>

        <!-- Per Year -->
        <div class="row p-0 mt-5">
          <div class="col-lg-12 p-0">
            <div
              class="card"
              style="
                background: rgba(255, 255, 255, 0.3);
                backdrop-filter: blur(5px);
                border-radius: 30px;
              "
            >
              <div class="row">
                <div
                  class="col p-0 d-flex year-indicator-section"
                  style="overflow: hidden"
                >
                  <div
                    class="card-body contract position-relative"
                    style="min-height: 307px; width: 50%"
                  >
                    <div
                      class="card-title mb-4 font-poppins titleBox position-absolute text-center"
                      style="
                        font-size: 20px;
                        font-weight: 600;
                        color: white;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        z-index: 1;
                      "
                    >
                      Contract Per Year
                    </div>
                    <!-- <div class="row text-center" style="height:50px;">
                    <div class="col-sm-10">
                      <h5 class="mb-0">Rp {{ dataSumContract.total_kontrak }} T</h5>
                      <p></p>
                    </div>
                  </div> -->
                    <Circle8 v-if="!dataSumContract_view"></Circle8>
                    <div class="mba" style="width: 100%; height: 100%">
                      <apexchart
                        class="apex-charts"
                        type="donut"
                        dir="ltr"
                        v-if="dataSumContract_view"
                        :series="dataSumContract.series"
                        :options="dataSumContract.chartOptions"
                      ></apexchart>
                    </div>
                    <!-- Bar Chart -->
                    <!-- <BarChartTotalContract v-if="dataSumContract_view" :data="dataSumContract" :height="300" /> -->
                  </div>
                  <div
                    class="card-body project position-relative"
                    style="min-height: 307px; width: 50%"
                  >
                    <div
                      class="card-title mb-4 font-poppins titleBox position-absolute text-center"
                      style="
                        font-size: 20px;
                        font-weight: 600;
                        color: white;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        z-index: 1;
                      "
                    >
                      Project Per Year
                    </div>
                    <!-- <div class="row text-center" style="height:50px;">
                    <div class="col-sm-4">
                      <h5 class="mb-0">100</h5>
                      <p class="text-muted">Company</p>
                    </div>
                  </div> -->
                    <!-- Bar Chart -->
                    <Circle8 v-if="!dataProjectYear_view"></Circle8>
                    <div class="mba" style="width: 100%; height: 100%">
                      <apexchart
                        class="apex-charts"
                        type="donut"
                        dir="ltr"
                        v-if="dataProjectYear_view"
                        :series="dataProjectYear.series"
                        :options="dataProjectYear.chartOptions"
                      ></apexchart>
                    </div>
                    <!-- <BarChartTotalProject :data="dataProjectYear" v-if="dataProjectYear != ''" :height="300" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white mx-4 card p-3" v-if="false">
          <div class="row">
            <div class="col-lg-12 p-3">
              <div
                class="mb-4 font-poppins titleBox"
                style="margin-bottom: 100px !important"
              >
                <div
                  class="float-left font-poppins"
                  style="font-size: 24px; font-weight: bold; color: #000000"
                >
                  Segmentation
                </div>
                <div class="float-right" style="width: 200px">
                  <multiselect
                    v-if="dataSecmentOwner_view"
                    :allowEmpty="false"
                    :options="dataSecmentOwner_temp.data_segmentasi"
                    v-model="v_data_segment_ow_computed"
                    label="name_segmentasi"
                  ></multiselect>
                </div>
                <div
                  class="float-right mx-2 font-poppins mt-2"
                  style="font-size: 16px; color: #000000"
                >
                  Filter :
                </div>
              </div>
              <div class="chart-seg">
                <apexchart
                  class="apex-charts"
                  height="300"
                  type="bar"
                  dir="ltr"
                  v-if="dataSecmentOwner_view"
                  :series="dataSecmentOwner.series"
                  :options="dataSecmentOwner.chartOptions"
                ></apexchart>

                <!-- <chartist
                    ratio="ct-chart"
                    :data="dataSecmentOwner.data"
                    :options="dataSecmentOwner.options"
                    type="Bar"
                  ></chartist> -->
              </div>
            </div>
            <!-- <div class="col-lg-4">
                <div class="row mt-2">
                    <div class="col-12">
                      <div class="text-center" style="margin: 0 auto;">
                        <div style="border-radius:50%; width: 10px; height: 10px ; background-color:#5438dc; margin-top:5px ; margin-right: 10px; float:left;"></div> 
                        <div style="margin-bottom:10px;  display: flex; justify-content: center; float:left; margin-right:10px;">Sesuai</div>
                        <div style="border-radius:50%; width: 10px; height: 10px ; background-color:#1cbb8c; margin-top:5px ; margin-right: 10px; float:left;"></div> 
                        <div style="margin-bottom:10px;  display: flex; justify-content: center; float:left;">Tidak Sesuai</div>
                      </div>
                  </div>
                  <div class="col-12">
                    <span class="mb-1" style="display: block; font-family:poppins;" v-for="(v,i) in dataSecmentOwner_temp.data_segment_ques" v-bind:key="i">{{ v.code }}. {{v.name}}</span>
                  </div>
                </div>
              </div> -->
          </div>
        </div>

        <!-- <div class="row">
            <div class="col-lg-12">
                <Sector />
            </div>
          </div> -->

        <!-- Assessment Criteria -->
        <div
          class="row mb-5 mt-5 justify-content-center section-assesment-criteria"
          style="
            background-color: rgb(255, 255, 255, 0.31);
            margin: 0 auto;
            width: 109%;
            right: 4%;
            position: relative;
          "
        >
          <div
            class="row col-lg-12 p-0 pt-4"
            style="max-width: 93% !important; margin: 0 auto"
          >
            <img
              style="
                position: absolute;
                top: -100px;
                right: 20px;
                color: #ffffff;
              "
              src="@/assets/images/womennongki.png"
            />

            <div class="col-lg-12 mb-4 ml-4">
              <span
                class="font-poppins"
                style="
                  font-weight: 700;
                  font-size: 30px;
                  line-height: 41px;
                  color: #000000;
                "
                >Assessment Criteria</span
              >
            </div>

            <div class="row assesment-criteria-card-list m-0 p-0">
              <template v-for="(v, i) in dataSection">
                <div class="col position-relative p-0" v-bind:key="i">
                  <div
                    class="position-absolute text-center"
                    style="
                      width: 50px;
                      height: 50px;
                      top: -20px;
                      right: -20px;
                      border-radius: 100%;
                      background-color: rgba(110, 89, 164, 0.85);
                      z-index: 1;
                    "
                  >
                    <img
                      style="margin: auto; height: 24px; margin-top: 12px"
                      :src="'' + v.image"
                    />
                  </div>
                  <div class="card glass-ul1">
                    <div
                      class="card-body"
                      style="
                        border-radius: 8px;
                        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
                      "
                    >
                      <div style="height: 50px; overflow:hidden;" class="overflowShow">
                        <span class="card-title mb-4 font-poppins">{{
                          v.section_name
                        }}</span>
                      </div>

                      <apexchart
                        class="apex-charts"
                        height="300"
                        type="bar"
                        dir="ltr"
                        :series="v.series"
                        :options="v.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-lg-4">
          <Sources />
        </div>
        <div class="col-lg-4">
          <RecentActivity />
        </div>
        <div class="col-lg-4">
          <RevenueLocation />
        </div>
      </div> -->
          <!-- <div class="row">
        <div class="col-lg-4">
          <Chat />
        </div>
        <div class="col-lg-8">
          <Transaction />
        </div>
      </div> -->
        </div>
        <!--   Top 5 Companies -->
        <div class="row mb-5 mt-2 p-0" v-if="client_id == ''">
          <div class="col-lg-6 pl-0 pr-4">
            <div
              class="card glass-ul1 p-0 min-height1"
              style="
                background-color: rgb(255, 255, 255, 0.31);
                border-radius: 20px;
              "
            >
              <div class="card-body">
                <span
                  class="card-title font-poppins titleBox"
                  style="
                    font-style: normal;
                    font-weight: 800;
                    font-size: 25px;
                    line-height: 34px;
                    color: #333333;
                  "
                >
                  Top 5 Companies
                </span>
                <div class="row mt-4">
                  <div
                    class="col-12 mb-4"
                    v-for="(v, i) in dataTopFive"
                    v-bind:key="i"
                  >
                    <div class="col-12">
                      <div
                        class="card pl-1 pr-1 mb-1 boxshadow-ul"
                        :style="{
                          minHeight: '100px',
                          backgroundColor: dataColorTop5[0][i],
                          color: dataColorTop5[1][i],
                          borderRadius: '20px',
                        }"
                      >
                        <div class="row p-3 font-poppins">
                          <div class="col-lg-5">
                            <a
                              v-b-tooltip.hover
                              :id="'top' + i"
                              :style="{
                                fontSize: '20px',
                                fontWeight: '600',
                                color: dataColorTop5[1][i],
                              }"
                              >#{{ v.kodefikasi }}</a
                            >
                            <b-tooltip
                              :target="'top' + i"
                              placement="rightbottom"
                            >
                              <div
                                style="
                                  width: 200px;
                                  min-height: 60px;
                                  position: relative;
                                  margin-top: -10px;
                                  margin-left: -10px;
                                "
                              >
                                <span
                                  style="
                                    font-weight: bold;
                                    text-align: center;
                                    font-size: 40px;
                                    color: rgba(255, 255, 255, 0.2);
                                  "
                                >
                                  {{ userStart.ad_user_name }}</span
                                >
                                <div
                                  class="text-white"
                                  style="
                                    padding-top: 3px;
                                    font-size: 14px;
                                    text-align: center;
                                    margin: auto;
                                    position: absolute;
                                    top: 25%;
                                    bottom: 25%;
                                    left: 5%;
                                    right: 5%;
                                    min-height: 60px;
                                  "
                                >
                                  {{ v.name }}
                                </div>
                              </div>
                            </b-tooltip>
                            <span style="display: block; white-space: nowrap"
                              >Jumlah Karya :
                              <span
                                style="
                                  font-weight: 600;
                                  font-size: 16px;
                                  line-height: 24px;
                                "
                                >{{ v.total_karya }}</span
                              >
                            </span>
                          </div>
                          <div class="col-lg-2 text-center">
                            <span style="font-size: 20px; font-weight: 600">{{
                              v.total_project
                            }}</span>
                            <span style="display: block">Project</span>
                          </div>
                          <div class="col-lg-2 text-center">
                            <span style="font-size: 20px; font-weight: 600"
                              >{{ v.total_nilai }} T</span
                            >
                            <span style="display: block">Rupiah</span>
                          </div>
                          <div class="col-lg-3 text-center">
                            <span style="font-size: 20px; font-weight: 600"
                              >{{
                                v.rating == null ? "0" : v.rating.toFixed(1)
                              }}
                            </span>
                            <span style="display: block">Rating</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pl-4 col-6 pr-0">
            <div
              class="card glass-ul1 p-0 min-height1"
              style="
                background-color: rgb(255, 255, 255, 0.32);
                border-radius: 20px;
              "
            >
              <div class="card-body">
                <span
                  class="card-title font-poppins titleBox"
                  style="
                    font-style: normal;
                    font-weight: 800;
                    font-size: 25px;
                    line-height: 34px;
                    color: #333333;
                  "
                >
                  Highlight Issue
                </span>

                <div class="row mt-4">
                  <div
                    class="col-12 mb-4"
                    v-for="(v, i) in dataHighIssue"
                    v-bind:key="i"
                  >
                    <div
                      class="card pl-4 mb-1 shadow boxshadow-ul"
                      :style="{
                        minHeight: '100px',
                        backgroundColor: dataColorHI5[0][i],
                        color: dataColorHI5[1][i],
                        borderRadius: '20px',
                      }"
                      style="
                        background-color: #fbd920;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
                      "
                    >
                      <div class="row p-3 font-poppins">
                        <div class="col-lg-5">
                          <span
                            :id="'hi' + i"
                            v-b-tooltip.hover
                            class="font-poppins"
                            style="font-size: 20px; font-weight: 600"
                            >#{{ v.kodefikasi }}</span
                          >
                          <b-tooltip :target="'hi' + i" placement="rightbottom">
                            <div
                              style="
                                width: 200px;
                                min-height: 60px;
                                position: relative;
                                margin-top: -10px;
                                margin-left: -10px;
                              "
                            >
                              <span
                                style="
                                  font-weight: bold;
                                  text-align: center;
                                  font-size: 40px;
                                  color: rgba(255, 255, 255, 0.2);
                                "
                              >
                                {{ userStart.ad_user_name }}</span
                              >
                              <div
                                class="text-white"
                                style="
                                  padding-top: 3px;
                                  font-size: 14px;
                                  text-align: center;
                                  margin: auto;
                                  position: absolute;
                                  top: 25%;
                                  bottom: 25%;
                                  left: 5%;
                                  right: 5%;
                                  min-height: 60px;
                                "
                              >
                                {{ v.name }}
                              </div>
                            </div>
                          </b-tooltip>
                          <span
                            class="font-poppins"
                            style="display: block; white-space: nowrap"
                            >Jumlah Karya :
                            <span
                              style="
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                              "
                              >{{ v.total_karya }}</span
                            >
                          </span>
                        </div>
                        <div class="col-lg-2 text-center">
                          <span style="font-size: 20px; font-weight: 600">{{
                            v.total_karya
                          }}</span>
                          <span style="display: block">Project</span>
                        </div>
                        <div class="col-lg-2 text-center">
                          <span style="font-size: 20px; font-weight: 600"
                            >{{ v.score == null ? "0" : v.score.toFixed(1) }}
                          </span>
                          <span style="display: block">Rating</span>
                        </div>
                        <div class="col-lg-3 text-left pt-1">
                          <div style="width: 100%">
                            <template
                              style="width: 25px; height: 25px"
                              v-for="(v1, i2) in v.issue_per_section"
                            >
                              <div
                                v-bind:key="i2"
                                :title="v1.name"
                                :id="'code' + i"
                                v-b-tooltip.hover
                                class="font-poppins float-left"
                                style="
                                  text-align: center;
                                  color: black;
                                  font-weight: 600;
                                  width: 20px;
                                  height: 20px;
                                  margin-right: 5px;
                                  border-radius: 100%;
                                  background-color: rgba(203, 203, 203, 0.73);
                                "
                              >
                                <img
                                  style="height: 16px"
                                  :src="'' + v1.image"
                                />
                              </div>
                            </template>
                          </div>
                          <div style="clear: both; margin-top: 26px">Issue</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row justify-content-center" style="margin-top:100px; ">
              <div class="col-xl-9" >
                <div class="card px-4" style="box-shadow:0px 0px 6px rgb(0,0,0,0.6);">
                  <div class="card-body text-center">
                    <span class="card-title font-poppins titleBox">Project Location</span>
                        <Markers />
                  </div>
                </div>
              </div>
    </div>  -->
      </div>
    </div>
  </Layout>
</template>
