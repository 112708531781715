var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 p-0 marker",staticStyle:{"position":"relative","z-index":"1"}},[_c('div',[(_vm.loading)?_c('Circle8'):_vm._e(),(!_vm.loading)?_c('l-map',{staticStyle:{"height":"400px","width":"100%"},attrs:{"zoom":_vm.zoom,"minZoom":5,"center":_vm.center,"options":{ zoomControl: false,scrollWheelZoom:false }}},[_c('l-control-layers',{attrs:{"position":"topright"}}),_c('l-control-zoom',{attrs:{"position":"bottomright"}}),_vm._l((_vm.tileProviders),function(tileProvider){return _c('l-tile-layer',{key:tileProvider.name,attrs:{"name":tileProvider.name,"visible":tileProvider.visible,"url":tileProvider.url,"layer-type":"base"}})}),_vm._l((_vm.markers),function(marker){return _c('l-marker',{key:marker.id,attrs:{"visible":marker.visible,"draggable":marker.draggable,"lat-lng":marker.position}},[_c('l-icon',{attrs:{"icon-anchor":_vm.staticAnchor}},[_c('img',{attrs:{"src":require("@/assets/images/pin.png"),"height":"32px;"}})])],1)})],2):_vm._e()],1)]),_c('div',{staticClass:"col-lg-3 mb-4 bg-white pl-1 py-4",staticStyle:{"box-shadow":"0px 0px 6px rgb(0, 0, 0, 0.3)","border-radius":"10px","height":"400px","overflow":"auto"}},[_c('div',{staticClass:"row justify-content-center"},_vm._l((this.datalokasi),function(v,i){return _c('div',{key:i,staticClass:"col-9 font-poppins p-0",style:({
          backgroundImage: 'url(' + v.image + ')',
          backgroundSize: 'cover',
          borderRadius: '5px',
          color: '#ffffff',
          height: '70px',
          marginTop: '10px',
          cursor: 'pointer',
        }),on:{"click":function($event){return _vm.goToPublicAssesment(v)}}},[_c('div',{staticClass:"px-3",staticStyle:{"background":"rgba(0, 0, 0, 0.6)","width":"100%","height":"100%","padding-top":"'3px'","border-radius":"5px","padding-bottom":"'2px'"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(v.name_provin))]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(v.total_assesment)+" Project")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }