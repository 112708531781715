<script>
import {
  LMap,
  LMarker,
  LTileLayer,
  LIcon,
  LControlLayers,
  LControlZoom,
} from "vue2-leaflet";
import { latLng } from "leaflet";
import axios from "axios";
import Config from "../../../config.js";
import { Circle8 } from "vue-loading-spinner";

export default {
  props: {
    client_id: [Object, String],
    year: String,
    segment_id: [Object, String],
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    Circle8,
    LControlLayers,
    LControlZoom,
  },
  data() {
    return {
      datalokasi: "",
      loading: false,
      tileProviders: [
        {
          name: "OpenStreetMap.HOT",
          visible: true,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
          url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        },
      ],
      data_url: Config,
      userStart: JSON.parse(localStorage.getItem("user")),
      zoom: 5,
      center: latLng(-1.960808, 117.545985),
      staticAnchor: [16, 37],
      markers: [],
    };
  },
  mounted() {
    if (Config.modeDev) console.log(this.client_id);
    if (Config.modeDev) console.log(this.segment_id);

    this.ApiCallLokasi();
  },
  methods: {
    goToPublicAssesment(data) {
      if (Config.modeDev) console.log(data);
      var mydata = {
        param_provinsi:data,
        param_client:this.client_id, 
        param_year:this.year, 
        param_segment:this.segment_id
      }
      this.$router.push({ name: "ViewPublicAssesment", params: mydata});
    },

    async ApiCallLokasi() {
      let formData = new URLSearchParams();
      if (this.client_id != "") {
        formData.append("partner_id", this.client_id.id);
      }
      if (this.year != "") {
        formData.append("year", this.year);
      }
      if (this.segment_id != "") {
        formData.append("segment_id", this.segment_id.id);
      }

      this.loading = true;
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.dashboardGetLokasi,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data"  "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.datalokasi = res.data.resultdata;
            let dataLok = [];

            for (let i = 0; i < this.datalokasi.length; i++) {
              dataLok[i] = {
                id: "m" + i,
                position: {
                  lat: parseFloat(this.datalokasi[i].lat),
                  lng: parseFloat(this.datalokasi[i].lng),
                },
                draggable: false,
                visible: true,
              };
            }
            if (Config.modeDev) console.log(this.markers);
            this.markers = dataLok;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },
  },
};
</script>

<style>
.leaflet-marker-icon > img {
  animation-name: bounce-4;
  animation-timing-function: ease;
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  /* background-color: #F44336;
          height: 200px;
          margin: 0 auto 0 auto;
          transform-origin: bottom;
          width: 200px; */
}

@keyframes bounce-4 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>

<template>
  <div class="row">
    <div class="col-lg-9 p-0 marker" style="position: relative; z-index: 1">
      <div>
        <Circle8 v-if="loading"></Circle8>
        <l-map
          v-if="!loading"
          style="height: 400px; width: 100%"
          :zoom="zoom"
          :minZoom="5"
          :center="center"
          :options="{ zoomControl: false,scrollWheelZoom:false }"
        >
          <l-control-layers position="topright"></l-control-layers>
          <l-control-zoom position="bottomright"></l-control-zoom>
          <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            layer-type="base"
          />
          <l-marker
            v-for="marker in markers"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng="marker.position"
          >
            <l-icon :icon-anchor="staticAnchor">
              <!-- <img src="@/assets/images/leaflet/marker-icon.png" /> -->
              <img src="@/assets/images/pin.png" height="32px;" />
            </l-icon>
          </l-marker>
        </l-map>
      </div>
    </div>
    <div
      class="col-lg-3 mb-4 bg-white pl-1 py-4"
      style="
        box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.3);
        border-radius: 10px;
        height: 400px;
        overflow: auto;
      "
    >
      <div class="row justify-content-center">
        <div
          v-for="(v, i) in this.datalokasi"
          @click="goToPublicAssesment(v)"
          v-bind:key="i"
          class="col-9 font-poppins p-0"
          :style="{
            backgroundImage: 'url(' + v.image + ')',
            backgroundSize: 'cover',
            borderRadius: '5px',
            color: '#ffffff',
            height: '70px',
            marginTop: '10px',
            cursor: 'pointer',
          }"
        >
          <div
            class="px-3"
            style="background: rgba(0, 0, 0, 0.6); width: 100%; height: 100%; padding-top: '3px'; border-radius:5px;
            padding-bottom: '2px';"
          >
            <span style="font-size: 20px">{{ v.name_provin }}</span> <br />
            <span style="font-size: 16px;">{{ v.total_assesment }} Project</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
